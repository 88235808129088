<template>
  <div class="layoutDefault">
    <div class="flex mt-10 justify-center relative z-10 xs:hidden md:flex">
      <div class="mr-4">
        <svg-menu class="text-center" />
      </div>

      <div>
        <span class="text-black text-2xl font-medium"> Main Menu </span>
      </div>
    </div>

    <div style="max-width: 964px" class="mx-auto overflow-hidden overflow-x-auto">
      <div class="flex w-full justify-between">
        <div
          class="group sm:relative mt-3 px-8 py-7 text-center z-10 menu-ticket hover:bg-base-300"
          v-for="m in menus"
          :key="m.id"
          @click="toPage(m.to)"
        >
          <div class="flex justify-center" style="height: 41px">
            <img class="self-end" :src="m.icon" style="margin-right: auto; margin-left: auto" />
          </div>
          <p class="font-medium mt-2 text-lg">{{ m.label }}</p>
          <p class="h-menu-sub whitespace-nowrap">{{ m.sublabel }}</p>

          <p
            class="xs:hidden sm:block h-0 w-full absolute left-0 transition-opacity opacity-0 group-hover:opacity-100"
            style="bottom: -1.2px; border: 3px solid #ff0701"
          ></p>
        </div>
      </div>
      <div class="h-0 w-full relative xs:hidden sm:block" style="border: 1px solid #adadad"></div>
    </div>

    <div style="max-width: 810px" class="mx-auto mt-12 xs:hidden md:block relative z-10">
      <div class="grid xs:grid-cols-1 md:grid-cols-2 xs:gap-5 md:gap-24">
        <div class="h-99" :class="loading ? 'animate-pulse' : ''">
          <p class="h-ticket-card-title">Ticket Progress</p>
          <div class="relative mt-2.5">
            <div class="h-ticket-blue"></div>
            <div class="h-ticket-card">
              <div class="grid grid-flow-row stats" v-if="ticketProgress && ticketProgress.length">
                <my-scroll :ops="opts" ref="vt">
                  <div
                    class="stat pl-0 pr-1 py-2 cursor-pointer hover:bg-hgray6"
                    v-for="tp in ticketProgress"
                    :key="tp.id"
                    @click="toDetail(tp)"
                  >
                    <div>
                      <div class="ticket-progress-label">{{ tp.number }}</div>

                      <div class="flex mt-1">
                        <div class="flex" v-if="tp.assigneeName">
                          <svg-pic-xs class="ml-2 mr-1 w-2.3 h-2.3" />
                          <span class="ticket-progress-sublabel">{{ tp.assigneeName }}</span>
                        </div>

                        <div class="flex" v-if="tp.created_at">
                          <IconTime class="mr-1 ml-3 w-2.3 h-2.3" />
                          <span class="ticket-progress-sublabel">
                            {{ tp.created_at.date | moment('D/MM/YYYY') }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div>
                      <img v-if="tp.status == 'new'" :src="StatusNew" />
                      <img v-if="tp.status == 'confirmed'" :src="StatusConfirmed" />
                      <img v-if="tp.status == 'started'" :src="StatusStarted" />
                      <img v-if="tp.status == 'held'" :src="StatusOnHold" />
                      <img v-if="tp.status == 'restarted'" :src="StatusRestarted" />
                    </div>
                  </div>
                </my-scroll>
              </div>
              <div class="grid grid-flow-row stats" v-else>
                <IconNoTicket class="mx-auto mt-20" />
                <div class="text-htext text-center font-semibold">There is no ticket yet!</div>
              </div>
            </div>
          </div>
        </div>
        <div class="h-99" :class="loading ? 'animate-pulse' : ''">
          <p class="h-ticket-card-title capitalize relative z-10">Please close your Ticket</p>
          <div class="relative mt-2.5">
            <div class="h-ticket-blue"></div>
            <div class="h-ticket-card">
              <div class="grid grid-flow-row stats" v-if="ticketDone && ticketDone.length">
                <my-scroll :ops="opts" ref="vt">
                  <div
                    class="stat px-2 py-2"
                    v-for="td in ticketDone"
                    :key="td.id"
                    :class="td.status == 'closed' ? '' : 'hover:bg-hgray6 cursor-pointer'"
                    @click="toDetail(td)"
                  >
                    <span class="text-sm font-medium">{{ td.number }}</span>

                    <button
                      class="btn btn-outline capitalize btn-ticket-close absolute hover:border-transparent right-2"
                    >
                      Done
                    </button>
                  </div>
                </my-scroll>
              </div>
              <div class="grid grid-flow-row stats" v-else>
                <IconNoTicket class="mx-auto mt-20" />
                <div class="text-htext text-center font-semibold">There is no ticket yet!</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconTicket from '@/assets/icons/icon-openTicket.svg'
import IconMyTicket from '@/assets/icons/icon-myTicket.svg'
import IconInstrument from '@/assets/icons/icon-instruments.svg'
import IconListPlan from '@/assets/icons/icon-plannings.svg'
import IconBot from '@/assets/icons/icon-bot.svg'

import StatusNew from '@/assets/images/icon-new.png'
import StatusConfirmed from '@/assets/images/icon-confirmed.png'
import StatusStarted from '@/assets/images/icon-started.png'
import StatusOnHold from '@/assets/images/icon-onhold.png'
import StatusRestarted from '@/assets/images/icon-restarted.png'

import SvgMenu from '@/assets/icons/icon-menu.svg?inline'
import SvgPicXs from '@/assets/icons/icon-picTicket.svg?inline'
import IconTime from '@/assets/icons/icon-timeColored.svg?inline'
import IconNoTicket from '@/assets/icons/icon-noTicket.svg?inline'

import { mapActions, mapState } from 'vuex'

export default {
  name: 'home',
  components: {
    SvgPicXs,
    SvgMenu,
    IconNoTicket,
    IconTime
  },
  data() {
    return {
      StatusNew,
      StatusConfirmed,
      StatusStarted,
      StatusOnHold,
      StatusRestarted,
      IconTicket,
      IconMyTicket,
      IconInstrument,
      IconListPlan,
      IconBot,

      opts: {
        vuescroll: {
          mode: 'native',
          sizeStrategy: 'number',
          detectResize: true,
          locking: true
        },
        scrollPanel: {
          scrollingX: false,
          scrollingY: true,
          verticalNativeBarPos: 'right',
          maxHeight: 345
        },
        bar: {
          background: '#176FA9',
          opacity: 1,
          size: '5px',
          minSize: 0.2
        }
      },
      ticketProgress: [],
      ticketDone: [],
      loading: false
    }
  },
  computed: {
    ...mapState({
      ticketTime: state => state.ticket.ticketTime,
      modalBot: state => state.modal.bot,
      haveInstrument: state => state.auth.haveInstrument
    }),
    menus: function () {
      let temp = [
        {
          id: 'open',
          label: 'Open Ticket',
          sublabel: 'Create your ticket here',
          icon: this.IconTicket,
          to: 'open.intro'
        },
        {
          id: 'my',
          label: 'My Ticket',
          sublabel: 'Manage your ticket here',
          icon: this.IconMyTicket,
          to: 'ticket.index'
        },
        {
          id: 'instrument',
          label: 'Instruments',
          sublabel: 'List of instruments used',
          icon: this.IconInstrument,
          to: 'instrument.index'
        },
        {
          id: 'planning',
          label: 'Plannings',
          sublabel: 'Schedule plan list',
          icon: this.IconListPlan,
          to: 'planning.index'
        }
      ]

      if (this.haveInstrument)
        temp.unshift({
          id: 'bot',
          label: 'Support Assistant',
          sublabel: 'Chat support virtual assistants',
          icon: this.IconBot,
          to: 'bot'
        })

      return temp
    }
  },
  watch: {
    ticketTime: function () {
      this.getTickets()
    }
  },
  mounted() {
    this.getTickets()
    this.CHECK_INSTRUMENTS()
  },
  methods: {
    ...mapActions('ticket', ['GET_TICKETS', 'TICKET_DETAIL']),
    ...mapActions('modal', ['MODAL_BOT']),
    ...mapActions('auth', ['CHECK_INSTRUMENTS']),
    toPage(val) {
      if (val == 'open.technical') this.$router.push({ name: val, params: { id: 1 } })
      else if (val == 'ticket.index')
        this.$router.push({
          name: val,
          query: { offset: 0, limit: 9, ticket_type: 0 }
        })
      else if (val == 'bot') this.MODAL_BOT(!this.modalBot)
      else this.$router.push({ name: val })
    },
    async getTickets() {
      this.loading = true
      let p = await this.GET_TICKETS({
        statuses: 'new,restarted,started,held,confirmed',
        offset: 0,
        limit: 50
      })
      if (p) this.ticketProgress = p

      let d = await this.GET_TICKETS({ statuses: 'done,closed' })
      if (d) this.ticketDone = d

      this.loading = false
    },
    toDetail(val) {
      if (val.status != 'closed') {
        let removeSpacing = val.number ? val.number.replaceAll(/\s+/g, '-') : ''
        let removeSlash = removeSpacing.replaceAll('/', '')
        this.$router.push({ name: 'ticket.detail', params: { slug: removeSlash, id: val.id } })
      }
    }
  }
}
</script>
